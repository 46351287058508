import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import {
  Button,
  Card,
  CardGroup,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  CardBody,
} from "reactstrap";

import "./Login.scss";

import ErrorToast from "../../utils/toasts/ErrorToast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../utils/Spinner";
import I18n from "../../utils/I18n";
import UserService from "../../../services/UserService";
import Errors from "../../constants/Errors";
import LocalStorageService from "../../../services/LocalStorageService";

const Constants = require("../../constants/Constants");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: false,
      error: false,
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      message: false,
      error: false,
      email: "",
      password: "",
    });
  }

  validateFields() {
    const emailRegex = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/;

    if (this.state.email === "") {
      this.setState({
        error: Errors.EDIT.MISSING.EMAIL,
      });
      return false;
    } else if (!emailRegex.test(this.state.email)) {
      this.setState({
        error: Errors.EDIT.INVALID.EMAIL,
      });
      return false;
    }

    return true;
  }

  // log in with the server and verify that the user is an admin
  onLogin = (event) => {
    console.log("ADASDAS")
    event.preventDefault();
    let self = this;

    if (!self.validateFields()) return;

    self.setState({ loading: true });
    debugger;
    UserService.login(
      this.state.email,
      this.state.password,
      async function (result) {
        debugger;
        const user = result.user;
        if (user.role === "ADMIN") {
          self.setState({ loading: false });
          LocalStorageService.set("token", result.session.token);
        } else {
          self.setState({
            error: Errors.LOGIN.NO_ADMIN,
            message: false,
            loading: false,
          });
        }
      },
      function (err) {
        debugger;
        console.log(err);
        self.setState({ error: err, message: false, loading: false });
      }
    );
  };

  // sends an email with the recovery code
  toSendRecoveryEmailPage = () => {
    //this.props.history.push(Constants.ROUTES.FORGOT);
  };

  // mail and password set
  isSendEnabled = () => {
    return this.state.email && this.state.password;
  };

  render() {
    if (LocalStorageService.get("token")) {
      return <Redirect to={Constants.ROUTES.DASHBOARD} />;
    }

    const loading = this.state.loading;
    const disabled = !this.isSendEnabled();
    return (
      <div
        className={
          loading
            ? "app flex-row align-items-center Loading"
            : "app flex-row align-items-center"
        }
      >
        <div className="loading">
          {Spinner.render(loading)}
        </div>
        <div className="col-md-8 col-sm-4 loginImageContainer">
          <img className="loginImage" src={"assets/img/home.png"} alt="startup@creativecoefficient.net"></img>
        </div>
        <div className="col-md-4 col-sm-8 col-xs-12  loginCardContainer">
          <CardGroup className="loginCardGroup">
            {this.renderLoginCard(disabled)}
          </CardGroup>
        </div>
      </div>
    );
  }

  renderErrorCard = () => {
    const error = this.state.error;
    return (
      error &&
      ErrorToast.notifyError(
        error.errorMessage || I18n.t("ERRORS.ERROR"),
        error.errorCode
      )
    );
  };

  renderLoginCard = (disabled) => {
    return (
      <Card className="p-4 loginCard">
        <img
          className={"logo"}
          src={"assets/img/Logo_drawbotic.png"}
          style={{ marginTop: 50 }}
        ></img>
        <CardBody className="loginCardBody">
          <Form onSubmit={this.onLogin} id="login-form" disabled={disabled}>
            <h2
              style={{
                textAlign: "center",
              }}
            >
              {I18n.t("LOGIN.TITLE")}
            </h2>
            <p className="text-muted" style={{ marginTop: 40 }}>
              {I18n.t("LOGIN.LOGIN")}
            </p>
            {this.renderEmailInput()}
            {this.renderPassInput()}
            <Row>
              <Col xs="6">{this.renderLoginButton(disabled)}</Col>
            </Row>
          </Form>

          <Row>
            <Col xs="12" m="12" className="text-center">
              <ToastContainer />
              <div>{this.renderErrorCard()}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderEmailInput = () => {
    return (
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-user"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          maxLength={Constants.SHORT_INPUT_MAX_LEN}
          placeholder={I18n.t("FIELDS.EMAIL")}
          onChange={(event) => {
            this.setState({
              email: event.target.value,
              error: false,
              message: false,
            });
          }}
        />
      </InputGroup>
    );
  };

  renderPassInput = () => {
    return (
      <InputGroup className="mb-4">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="icon-lock"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type="password"
          placeholder={I18n.t("FIELDS.PASS")}
          maxLength={Constants.SHORT_INPUT_MAX_LEN}
          autoComplete="on"
          onChange={(event) => {
            this.setState({
              password: event.target.value,
              error: false,
              message: false,
            });
          }}
        />
      </InputGroup>
    );
  };

  renderForgotButton = () => {
    return (
      <Button
        color="link"
        className="px-0"
        style={{ paddingTop: "0px" }}
        onClick={this.toSendRecoveryEmailPage}
      >
        {I18n.t("LOGIN.FORGOT_PASSWORD")}
      </Button>
    );
  };

  renderLoginButton = (disabled) => {
    return (
      <Button
        className="px-4"
        type="submit"
        form="login-form"
        disabled={disabled}
        style={{ backgroundColor: "#004881", color: "white" }}
      >
        {I18n.t("LOGIN.BUTTON")}
      </Button>
    );
  };
}

export default withRouter(Login);
