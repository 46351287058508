import React, { Component, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import I18n from "../src/views/utils/I18n";
import CookieService from "../src/services/CookieService";

import "./App.scss";
import Login from "./views/Pages/Login/Login";
import LocalStorageService from "./services/LocalStorageService";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Constants = require("./views/constants/Constants");

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

class App extends Component {
  constructor(props) {
    super(props);

    let lang = LocalStorageService.get("language");
    if (!lang) {
      lang = Constants.DEFAULT_LANGUAGE;
      LocalStorageService.set("language", lang);
    }
  }

  componentDidMount() {
    LocalStorageService.replaceCurrentObserverWith((name) => {
      if (name === "token") {
        this.forceUpdate();
      }
    });
  }
  componentWillUnmount() {
    LocalStorageService.replaceCurrentObserverWith(null);
  }

  render() {
    const token = LocalStorageService.get("token");

    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {!token ? (
              <>
                <Redirect to={Constants.ROUTES.LOGIN} />
                <Route path={Constants.ROUTES.LOGIN} component={Login} />
              </>
            ) : (
              <>
                <Switch>
                  <Route exact path="/">
                    <Redirect to={Constants.ROUTES.DASHBOARD} />
                  </Route>

                  <Route
                    exact
                    path={Constants.ROUTES.DASHBOARD}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.USERS}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.EDIT_USER(":userId")}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.CREATE_USER}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.WORDLIST}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.EDIT_CONTENT(":contentId")}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.CREATE_CONTENT}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.DEBT}
                    render={(props) => <DefaultLayout {...props} />}
                  />
                  <Route
                    exact
                    path={Constants.ROUTES.FEED}
                    render={(props) => <DefaultLayout {...props} />}
                  />

                  <Route
                      exact
                      path={Constants.ROUTES.PHRASE}
                      render={(props) => <DefaultLayout {...props} />}
                  />

                  <Route
                    exact
                    path="/construction"
                    render={(props) => <DefaultLayout {...props} />}
                  />
                </Switch>
              </>
            )}
            <Route render={(props) => <Page404 {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
