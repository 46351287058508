import React from "react";
import "./Spinner.css";

export default class Spinner {

  static render(loading) {
    return (
      <div
        style={{
          display: loading ? "" : "none",
          position: "sticky",
          top: "calc(50vh - 100px)",
        }}
      >
          <div className="spinner-container">
              <div className="loading-spinner"></div>
          </div>
      </div>
    );
  }
}
