import I18n from "./../utils/I18n";

const errors = {
  LOGIN: {
    NO_ADMIN: { errorCode: 403, errorMessage: I18n.t("LOGIN.NO_ADMIN") },
    NO_ACTIVE: { errorCode: 403, errorMessage: I18n.t("LOGIN.NO_ACTIVE") },
  },
  EDIT: {
    MISSING: {
      NAME: { errorCode: 422, errorMessage: I18n.t("ERRORS.NAME_MISSING") },
      LAST_NAME: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.LAST_NAME_MISSING"),
      },
      EMAIL: { errorCode: 422, errorMessage: I18n.t("ERRORS.EMAIL_MISSING") },
      PASSWORD: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.PASSWORD_MISSING"),
      },
    },
    INVALID: {
      EMAIL: { errorCode: 422, errorMessage: I18n.t("ERRORS.INVALID_EMAIL") },
      PASSWORD: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.INVALID_PASSWORD"),
      },
    },
  },
  CREATE_WORD_LIST: {
    MISSING: {
      NAME: { errorCode: 422, errorMessage: I18n.t("ERRORS.NAME_MISSING") },
      LANGUAGE: { errorCode: 422, errorMessage: I18n.t("ERRORS.LANGUAGE_MISSING") },
      DESCRIPTION: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.DESCRIPTION_MISSING"),
      },
      TYPE: { errorCode: 422, errorMessage: I18n.t("ERRORS.TYPE_MISSING") },
      FILE_CODE: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.CODE_MISSING"),
      },
      FILE: { errorCode: 422, errorMessage: I18n.t("ERRORS.FILE_MISSING") },
    },
    INVALID: {
      CODE: { errorCode: 422, errorMessage: I18n.t("ERRORS.CODE_USED") },
      CODE_SPECIAL: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.CODE_SPECIAL"),
      },
      TYPE: { errorCode: 422, errorMessage: I18n.t("ERRORS.INVALID_TYPE") },
    },
  },
  CREATE_CONTENT: {
    MISSING: {
      TITLE: { errorCode: 422, errorMessage: I18n.t("ERRORS.TITLE_MISSING") },
      DESCRIPTION: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.DESCRIPTION_MISSING"),
      },
      TYPE: { errorCode: 422, errorMessage: I18n.t("ERRORS.TYPE_MISSING") },
      FILE_CODE: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.CODE_MISSING"),
      },
      FILE: { errorCode: 422, errorMessage: I18n.t("ERRORS.FILE_MISSING") },
    },
    INVALID: {
      CODE: { errorCode: 422, errorMessage: I18n.t("ERRORS.CODE_USED") },
      CODE_SPECIAL: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.CODE_SPECIAL"),
      },
      TYPE: { errorCode: 422, errorMessage: I18n.t("ERRORS.INVALID_TYPE") },
    },
  },
  DEBT: {
    INVALID: {
      TYPE: {
        errorCode: 422,
        errorMessage: I18n.t("ERRORS.INVALID_DATA_FORMAT"),
      },
    },
  },
};

export default errors;
