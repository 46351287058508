export default class LocalStorageService {
  static callbacks = null;

  static get(key, isJson = false) {
    const value = localStorage.getItem(key);
    if (isJson) {
      return JSON.parse(value);
    }
    return value
  }

  static set(key, value, isJson = false) {
    const valueToSave = isJson ? JSON.stringify(value) : value
    localStorage.setItem(key, valueToSave);
    if (this.callbacks) {
      this.callbacks(key);
    }
  }

  static replaceCurrentObserverWith(cb) {
    this.callbacks = cb;
  }

  static delete(key) {
    localStorage.removeItem(key);
    if (this.callbacks) {
      this.callbacks(key);
    }
  }
}
