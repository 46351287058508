import CookieService from "./CookieService";
import LocalStorageService from "./LocalStorageService";
const Constants = require("../views/constants/Constants");

export default class HttpService {
  static httpCall(method, url, body, token, cb, errCb, noRetry) {
    console.log(token)
    const data = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      data["headers"]["Authorization"] = token;
      data["headers"]["token"] = token;
    }

    if (body) {
      data["body"] = JSON.stringify(body);
    }

    let statusCode;
    let respToken;
    fetch(url, data)
      .then((data) => {
        for (var pair of data.headers.entries()) {
          if (pair[0] === "authorization") {
            respToken = pair[1];
          }
        }

        statusCode = data.status;
        console.log(statusCode);
        return data.json();
      })
      .then((data) => {
        if (statusCode < 300) {
          if (
            data.data &&
            data.data.session &&
            data.data.session.refreshToken
          ) {
            LocalStorageService.set("refreshToken", data.data.session.refreshToken);
          }
          if (respToken) {
            LocalStorageService.set("token", respToken);
          }
          return cb(data.data, respToken);
        } else {
          debugger
          if (statusCode === 401) {
            LocalStorageService.delete("token");
          }
          return errCb({
            errorMessage: data?.errorMessage,
            errorCode: statusCode,
          });
        }
      })
      .catch((err) => {
        if (err.errorCode === 401) LocalStorageService.delete("token");
        // LocalStorageService.delete("token");
        console.log(err);
        if (!err.errorMessage)
          err = { errorMessage: err.message, errorCode: 404 };
        return errCb(err);
      });
  }

  static httpImageCall(method, url, formData, token, cb, errCb) {
    const data = {
      method: method,
      headers: {
        // "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        // type: "formData",
      },
    };

    if (token) {
      data["headers"]["Authorization"] = token;
      data["headers"]["token"] = token;
    }

    if (formData) {
      data["body"] = formData;
    }

    let statusCode;
    let respToken;
    fetch(url, data)
      .then((data) => {
        statusCode = data.status;
        return data.json();
      })
      .then((data) => {
        if (statusCode < 300) {
          return cb(data.data, respToken);
        } else {
          errCb({ errorCode: statusCode, errorMessage: data.errorMessage });
        }
      })
      .catch((err) => {
        if (err.errorCode === 401) {
          HttpService.refreshToken(function () {
            HttpService.httpImageCall(method, url, formData, token, cb, errCb);
          }, errCb);
        }

        console.log(err);
        if (!err.errorMessage)
          err = { errorMessage: err.message, errorCode: 404 };
        return errCb(err);
      });
  }

  static refreshToken(cb, errCb) {
    const refreshToken = LocalStorageService.get("refreshToken");
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.REFRESH_TOKEN,
      undefined,
      refreshToken,
      cb,
      errCb,
      true
    );
  }
}
